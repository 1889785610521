import LogoBlueYellow from '../../public/svg/paymenttools_logo_blue_yellow.svg'
import LogoBlueWhite from '../../public/svg/paymenttools_logo_blue_white.svg'
import LogoYellowWhite from '../../public/svg/paymenttools_logo_yellow_white.svg'
// import LogoOneLove from '../../public/svg/paymenttools_logo_one_love.png'
// import styles from './Logo.module.scss'
// import ImageComponent from '../Image'
// import cx from 'classnames'

export const Logo = ({ color = 'blue_white' }) => {
  // export const Logo = ({ className }) => {
  // return (
  //   <div className={cx(styles.logo, className)}>
  //     <ImageComponent src={LogoOneLove} layout={'responsive'} />
  //   </div>
  // )

  return (
    (color === 'blue_white' && <LogoBlueWhite />) ||
    (color === 'blue_yellow' && <LogoBlueYellow />) ||
    (color === 'yellow_white' && <LogoYellowWhite />)
  )
}

export default Logo
