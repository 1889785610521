import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

const Loader = ({ className }) => (
  <div className={className}>
    <svg
      className="w-5 h-5"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
    >
      <circle
        className="opacity-25"
        cx="12"
        cy="12"
        r="10"
        stroke="currentColor"
        strokeWidth="4"
      />
      <path
        className="opacity-75"
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      />
    </svg>
  </div>
)

// eslint-disable-next-line react/display-name
export const Button = forwardRef(
  (
    {
      as = 'button',
      children,
      className,
      disabled,
      isLoading,
      fullWidth,
      color = 'yellow',
      small = false,
      ...rest
    },
    ref
  ) => {
    const Tag = as

    const classNames = cx(
      'inline-flex items-center cursor-pointer font-medium border-2 justify-center lg:text-16-28 rounded-[10px] transition duration-150',
      {
        'bg-yellow hover:bg-yellow-light border-transparent text-purple':
          color === 'yellow',
        'bg-white border-purple hover:border-purple-button-hover text-purple hover:text-purple-button-hover':
          color === 'white',
        'bg-purple hover:bg-purple-button-hover border-purple hover:border-purple-button-hover text-white':
          color === 'purple' || color === 'blue',
        'bg-transparent border-purple hover:border-purple-button-hover hover:text-purple-button-hover':
          color === 'transparent',
        'bg-transparent border-white text-white hover:border-gray-lightest hover:text-gray-lightest':
          color === 'transparent-white',
        'w-full': fullWidth,
        'cursor-not-allowed opacity-50': disabled,
        'text-18-27 px-[2rem] py-[1.25rem]': !small,
        'text-15-23 px-[1.5rem] py-[0.8rem]': small,
      },
      className
    )

    return (
      <Tag ref={ref} className={classNames} disabled={disabled} {...rest}>
        <Loader
          className={`absolute inset-x-auto inset-y-auto ${
            isLoading ? 'opacity-100 animate-spin' : 'opacity-0'
          }`}
        />
        <div className={`${isLoading && 'invisible'}`}>{children}</div>
      </Tag>
    )
  }
)

Button.propTypes = {
  isLoading: PropTypes.bool,
}

Button.defaultProps = {
  isLoading: false,
}

export default Button
