import React, { useEffect, useRef, useState } from 'react'
import videojs from 'video.js'
import 'video.js/dist/video-js.css'
import { Play, Pause } from 'react-feather'
import styles from './Video.module.scss'
import cx from 'classnames'

export const VideoJS = props => {
  const [isPlaying, setIsPlaying] = useState(false)
  const [showPlayButton, setShowPlayButton] = useState(true)

  const videoRef = useRef(null)
  const playerRef = useRef(null)
  const { options, onReady } = props

  useEffect(() => {
    setShowPlayButton(!isPlaying)
  }, [isPlaying])

  useEffect(() => {
    // Make sure Video.js player is only initialized once
    if (!playerRef.current) {
      const videoElement = videoRef.current

      if (!videoElement) return

      const player = (playerRef.current = videojs(
        videoElement,
        options,
        () => onReady && onReady(player)
      ))

      player.autoplay(options.autoplay)
      player.src(options.sources)
    }
  }, [onReady, options, videoRef])

  // Dispose the Video.js player when the functional component unmounts
  useEffect(() => {
    const player = playerRef.current

    return () => {
      if (player) {
        player.dispose()
        playerRef.current = null
      }
    }
  }, [playerRef])

  const handleVideoPause = e => {
    e.preventDefault()
    setIsPlaying(false)
    setShowPlayButton(true)
  }
  const handleVideoToggle = e => {
    e.preventDefault()
    if (isPlaying) {
      playerRef.current.pause()
    } else {
      playerRef.current.play()
    }
    setIsPlaying(!isPlaying)
  }

  return (
    <div
      className={styles.wrapper}
      onMouseLeave={e => {
        e.preventDefault()
        setShowPlayButton(false)
      }}
      onMouseEnter={e => {
        e.preventDefault()
        setShowPlayButton(true)
      }}
      onClick={handleVideoToggle}
      onTouchEnd={handleVideoToggle}
      onAbort={handleVideoPause}
      onEnded={handleVideoPause}
      onPause={handleVideoPause}
      // onSuspend={handleVideoPause}
      data-vjs-player
    >
      <video
        ref={videoRef}
        className={cx(styles.videoElement, 'video-js vjs-big-play-centered')}
      />
      {showPlayButton && (
        <div className={cx(styles.buttonWrapper, 'group')}>
          <div className={styles.bgCircle} />
          {isPlaying ? (
            <Pause className={cx(styles.icon, styles.pause)} />
          ) : (
            <Play className={cx(styles.icon, styles.play)} />
          )}
        </div>
      )}
    </div>
  )
}

export default VideoJS
