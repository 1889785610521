import React from 'react'
export const SiteInformationContext = React.createContext(undefined)

export const SiteInformationProvider = ({ children, siteInformation = {} }) => {
  return (
    <SiteInformationContext.Provider value={siteInformation}>
      {children}
    </SiteInformationContext.Provider>
  )
}
