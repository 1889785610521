import NextImage from 'next/image'
import NextFutureImage from 'next/future/image'
import { useState } from 'react'
import styles from './Image.module.scss'
import cx from 'classnames'
import ImageLightbox from 'react-image-lightbox'
import 'react-image-lightbox/style.css'

export const ImageComponent = props => {
  const [zoomModalOpen, setZoomModalOpen] = useState(false)

  const src = props.src
  if (!src) return null

  const isSvg = typeof src === 'string' && src.endsWith('.svg')
  const isGif = typeof src === 'string' && src.endsWith('.gif')
  const isObject = typeof src === 'object'

  let url = isObject
    ? null
    : isSvg || isGif
    ? src
    : props?.src?.toString()?.replace('/assets', '/img/assets')

  if (props.proxyimage) {
    url = '/api/image?b=' + btoa(url)
  }

  const getImageUrl = (url, width, quality = 80) => {
    if (isSvg || isGif) return url
    return `/_next/image?w=${width}&q=${quality}&url=${url}`
  }

  let TAG = NextImage
  if (props.future) {
    TAG = NextFutureImage
  }

  return (
    <>
      {zoomModalOpen && (
        <ImageLightbox
          mainSrc={props.src}
          onCloseRequest={() => setZoomModalOpen(false)}
        />
      )}

      {isObject ? (
        <TAG {...props} src={src} placeholder={'empty'} />
      ) : (
        <TAG
          {...props}
          src={url}
          placeholder={'empty'}
          blurDataURL={isSvg || isGif ? url : getImageUrl(url, 10, 80)}
          loader={({ src, width, quality }) =>
            getImageUrl(src, width, quality || 80)
          }
          className={cx({
            [styles.imageHover]: props.zoom,
          })}
          onClick={() => {
            if (props.zoom) {
              setZoomModalOpen(true)
            }
          }}
        />
      )}
    </>
  )
}

export default ImageComponent
