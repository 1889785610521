import setLanguage from 'next-translate/setLanguage'
import useTranslation from 'next-translate/useTranslation'
import { Globe } from 'react-feather'
import { useEffect, useState } from 'react'
import Select, { components } from 'react-select'
import { useRouter } from 'next/router'

const globalLanguages = {
  de: 'Deutsch',
  en: 'English',
}

const LanguageSwitcher = ({ pageInformation = {} }) => {
  const { lang } = useTranslation()
  const router = useRouter()

  const changeLanguage = async ({ value: newLocale, uri: newUri }) => {
    if (router.asPath.indexOf('/jobs') === 0) {
      if (window?.jobSwitchUrls) {
        await router.push(
          {
            pathname: window.jobSwitchUrls[newLocale],
          },
          null,
          { locale: newLocale }
        )
      }
    } else if (router.asPath.indexOf('/blog') === 0) {
      await setLanguage(newLocale)
    } else {
      await router.push(
        {
          pathname: newUri,
        },
        null,
        { locale: newLocale }
      )
    }
  }

  const [availableLanguages, setAvailableLanguages] = useState(null)

  useEffect(() => {
    setAvailableLanguages(
      Object.entries(globalLanguages).map(([value, label]) => {
        let uri =
          pageInformation?.locale === value
            ? `/${pageInformation?.locale}${pageInformation?.uri}` || null
            : `/${value}${pageInformation?.locale_versions?.[value]?.uri}` ||
              null

        uri = uri.replace('/de/', '/')

        return {
          value,
          label,
          isDisabled: lang === value,
          uri,
        }
      })
    )
  }, [lang, pageInformation])

  return (
    <>
      {availableLanguages && (
        <Select
          options={availableLanguages}
          defaultValue={availableLanguages.find(
            language => language.value === lang
          )}
          onChange={changeLanguage}
          name="language"
          isClearable={false}
          isSearchable={false}
          components={{
            ValueContainer: ({ children, ...props }) => {
              return (
                components.ValueContainer && (
                  <components.ValueContainer {...props}>
                    <Globe className={'mr-2'} />
                    {children}
                  </components.ValueContainer>
                )
              )
            },
          }}
          styles={{
            control: styles => ({
              ...styles,
              backgroundColor: '#212840',
              color: '#fff',
              boxShadow: 'none',
              cursor: 'pointer',
              borderColor: '#212840',
              '&:hover': {
                border: '1px solid #212840',
              },
              maxWidth: '200px',
              width: '200px',
            }),
            input: styles => ({
              ...styles,
              border: 'none',
              outline: 'none',
              boxShadow: 'none',
              '& input:focus': {
                boxShadow: 'none',
              },
            }),
            indicatorSeparator: styles => ({
              ...styles,
              display: 'none',
            }),
            singleValue: styles => ({
              ...styles,
              color: '#fff',
              borderRadius: '2px 0 0 2px',
              paddingLeft: '0.75rem',
              paddingRight: '0.75rem',
            }),
            valueContainer: styles => ({
              ...styles,
              padding: '8px 10px',
              display: 'flex',
              alignItems: 'center',
            }),
            menu: styles => ({
              ...styles,
              backgroundColor: '#212840',
              maxWidth: '200px',
              width: '200px',
            }),
            option: (styles, state) => ({
              ...styles,
              backgroundColor: '#212840',
              color: state.data.isDisabled ? '#2E3859' : '#FFF',
            }),
          }}
        />
      )}
    </>
  )
}

export default LanguageSwitcher
