import { Header } from './Header'
import { Footer } from './Footer'

export const Layout = ({
  children,
  headerColor = 'white',
  hideHeader = true,
  footerWithCareer = true,
  pageInformation = {},
}) => {
  return (
    <div className={'min-h-screen flex flex-col justify-between'}>
      <div>
        <Header color={headerColor} hideHeader={hideHeader} />
        <main>{children}</main>
      </div>
      <Footer withCareer={footerWithCareer} pageInformation={pageInformation} />
    </div>
  )
}

export default Layout
