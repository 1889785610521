import Button from '../../Button'
import styles from './CustomLink.module.scss'
import cx from 'classnames'
import { ChevronRight } from 'react-feather'

export const CustomLink = ({
  link,
  newTab,
  color,
  children,
  className,
  linkType = { key: 'type_button' },
}) => {
  const type = linkType.key

  return type === 'type_link' ? (
    // eslint-disable-next-line react/jsx-no-target-blank
    <a
      href={link}
      className={cx(styles.link, className)}
      rel={newTab ? 'noreferrer' : ''}
      target={newTab ? '_blank' : ''}
    >
      {children}
      <ChevronRight />
    </a>
  ) : (
    <Button
      small
      as="a"
      color={color}
      className={className}
      href={link}
      rel={newTab ? 'noreferrer' : ''}
      target={newTab ? '_blank' : ''}
    >
      {children}
    </Button>
  )
}

export default CustomLink
