import styles from './TextMedia.module.scss'
import cx from 'classnames'
import Media from '../Media'
import CustomLink from '../CustomLink'
import { useEffect, useState } from 'react'
import cleanString from '../../../lib/cleanString'
import Section from '../../Section'

export const TextMedia = ({
  content,
  isSection = true,
  inColoredContainer = false,
  className,
}) => {
  const {
    link,
    linktext,
    linktype,
    newtab,
    color,
    headline,
    linkedvideo,
    mediatype,
    uploadedasset,
    layout,
    teaser,
    shadow,
    headline_small: headlineSmall,
    image_fit: imageFit,
    imagepadding,
    mediablock_color,
    mediablock_gradient: gradient,
    mediablock_gradient_color: gradientColor,
    mediablock_gradient_position: gradientPosition,
  } = content

  const [hasMedia, setHasMedia] = useState(false)

  useEffect(() => {
    setHasMedia(mediatype && (uploadedasset || linkedvideo))
  }, [mediatype, uploadedasset, linkedvideo])

  return isSection ? (
    <Section
      classNames={cx(
        styles.sectionWrapper,
        {
          [styles.reverse]: layout.value === 'right',
        },
        className
      )}
      color={mediablock_color?.value}
      gradient={gradient}
      gradientColor={gradientColor?.value}
      gradientPosition={gradientPosition?.value}
    >
      <div className={styles.sectionInner}>
        {hasMedia && (
          <div
            className={cx(
              styles.mediaWrapper,
              {
                [styles.mediaPadding]: imagepadding,
                [styles.mediaPaddingRight]:
                  !imagepadding && layout.value === 'right',
                [styles.mediaPaddingRight]:
                  !imagepadding && layout.value === 'left',
                [styles.video]: uploadedasset?.is_video,
                [styles.image]: uploadedasset?.is_image,
              },
              'mediaWrapper'
            )}
          >
            <Media
              className={cx(styles.media, {
                [styles.shadow]: shadow,
                [styles.rounded]: uploadedasset?.is_image,
                [styles.roundedRight]:
                  !imagepadding &&
                  inColoredContainer &&
                  layout.value === 'left',
                [styles.roundedLeft]:
                  !imagepadding &&
                  inColoredContainer &&
                  layout.value === 'right',
                [styles.video]: uploadedasset?.is_video,
                [styles.image]: uploadedasset?.is_image,
              })}
              content={{
                linkedvideo: linkedvideo,
                mediatype: mediatype,
                uploadedasset: uploadedasset,
              }}
              imageProps={{
                layout: 'fill',
                objectFit: imageFit?.value || 'cover',
                objectPosition: uploadedasset?.focus_css || 'center',
              }}
              isSection={false}
            />
          </div>
        )}
        <div
          className={cx(styles.content, {
            [styles.fullWidth]: !hasMedia,
            [styles.halfWidth]: hasMedia,
          })}
        >
          <h2
            className={cx({
              [styles.headline]: !headlineSmall,
              [styles.headlineSmall]: headlineSmall,
              'text-white':
                mediablock_color?.value === 'yellow' ||
                mediablock_color?.value === 'dark-blue' ||
                mediablock_color?.value === 'blue',
              'text-purple': mediablock_color?.value === 'white',
            })}
            dangerouslySetInnerHTML={{ __html: cleanString(headline) }}
          />
          <div
            className={cx(styles.teaser, {
              'text-white':
                mediablock_color?.value === 'yellow' ||
                mediablock_color?.value === 'dark-blue' ||
                mediablock_color?.value === 'blue',
              'text-purple': mediablock_color?.value === 'white',
            })}
            dangerouslySetInnerHTML={{ __html: teaser }}
          />
          {link && (
            <CustomLink
              className={'mt-9'}
              link={link}
              linkType={linktype}
              color={color?.value}
              newTab={newtab}
            >
              {linktext}
            </CustomLink>
          )}
        </div>
      </div>
    </Section>
  ) : (
    <div
      className={cx(
        styles.divWrapper,
        {
          [styles.reverse]: layout.value === 'right',
        },
        className
      )}
    >
      <div className={styles.sectionInner}>
        {hasMedia && (
          <div
            className={cx(
              styles.mediaWrapper,
              {
                [styles.mediaPadding]: imagepadding,
                [styles.mediaPaddingRight]:
                  !imagepadding && layout.value === 'right',
                [styles.mediaPaddingRight]:
                  !imagepadding && layout.value === 'left',
                [styles.video]: uploadedasset?.is_video,
                [styles.image]: uploadedasset?.is_image,
              },
              'mediaWrapper'
            )}
          >
            <Media
              className={cx(styles.media, {
                [styles.shadow]: shadow,
                [styles.rounded]: uploadedasset?.is_image,
                [styles.roundedRight]:
                  !imagepadding &&
                  inColoredContainer &&
                  layout.value === 'left',
                [styles.roundedLeft]:
                  !imagepadding &&
                  inColoredContainer &&
                  layout.value === 'right',
                [styles.video]: uploadedasset?.is_video,
                [styles.image]: uploadedasset?.is_image,
              })}
              content={{
                linkedvideo: linkedvideo,
                mediatype: mediatype,
                uploadedasset: uploadedasset,
              }}
              imageProps={{
                layout: 'fill',
                objectFit: imageFit?.value || 'cover',
                objectPosition: uploadedasset?.focus_css || 'center',
              }}
              isSection={false}
            />
          </div>
        )}
        <div
          className={cx(styles.content, {
            [styles.fullWidth]: !hasMedia,
            [styles.halfWidth]: hasMedia,
          })}
        >
          <h2
            className={cx({
              [styles.headline]: !headlineSmall,
              [styles.headlineSmall]: headlineSmall,
            })}
            dangerouslySetInnerHTML={{ __html: cleanString(headline) }}
          />
          <div
            className={cx(styles.teaser, {})}
            dangerouslySetInnerHTML={{ __html: teaser }}
          />
          {link && (
            <CustomLink
              className={'mt-9'}
              link={link}
              linkType={linktype}
              color={color?.value}
              newTab={newtab}
            >
              {linktext}
            </CustomLink>
          )}
        </div>
      </div>
    </div>
  )
}

export default TextMedia
