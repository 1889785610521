import axios from 'axios'
import useSWR from 'swr'

export const useJobPublications = (lang, options) => {
  const { data, error } = useSWR(`/api/jobs/publications?lang=${lang}`, options)

  return {
    jobs: data,
    isLoading: !error && !data,
    isError: error,
  }
}

export const useJobPublication = (id, lang, options) => {
  const { data, error } = useSWR(
    id ? `/api/jobs/publications/${id}?lang=${lang}` : null,
    options
  )

  return {
    job: data,
    isLoading: !error && !data,
    isError: error,
  }
}

export const useJobsSelection = options => {
  const { data, error } = useSWR(`/api/jobs/selection`, options)

  return {
    jobs: data,
    isLoading: !error && !data,
    isError: error,
  }
}

export const applyForJob = (id, lang, data, dryrun = true) => {
  return axios.post(`/api/jobs/applications/${id}`, data, {
    params: {
      lang,
      dryrun: dryrun ? 1 : 0, // Enable to make a local-only request w/o d.vinci
    },
    maxBodyLength: 104857600, //100mb
    maxContentLength: 104857600, //100mb
  })
}
