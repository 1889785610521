import useTranslation from 'next-translate/useTranslation'
import { SiteInformationContext } from '../context/site-information-context'
import { useContext, useEffect, useRef, useState } from 'react'
import { Logo } from './Logo'
import { Button } from './Button'
import styles from '../styles/Header.module.scss'
import {
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  ChevronUp,
  Menu,
  X,
} from 'react-feather'
import { useRouter } from 'next/router'
import cx from 'classnames'
import { useJobPublications } from '../lib/data/use-jobs'
import Link from 'next/link'
const _ = require('lodash')

export const Header = ({ color = 'white', hideHeader = true }) => {
  const { t, lang } = useTranslation('common')

  const router = useRouter()
  const [isHomepage, setIsHomepage] = useState(false)
  useEffect(() => {
    setIsHomepage(router.pathname === '/')
  }, [router.pathname])

  const siteInformation = useContext(SiteInformationContext)

  const logoSection = useRef(null)
  const navPopout = useRef(null)

  const [activeNavigationItem, setActiveNavigationItem] = useState(null)
  const [leftSectionWidth, setLeftSectionWidth] = useState('auto')

  const openPopout = navEntry => {
    setLeftSectionWidth(logoSection?.current?.offsetWidth || 'auto')
    setActiveNavigationItem(null)
    setActiveNavigationItem({
      id: navEntry.page.id,
      navEntry,
      childCount: navEntry.children?.length || 0,
    })
  }
  const closePopout = () => {
    setActiveNavigationItem(null)
  }

  const togglePopout = navEntry => {
    // toggle on click to enable closing
    if (activeNavigationItem) {
      closePopout()
    } else {
      openPopout(navEntry)
    }
  }

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  const [mobileNavItems, setMobileNavItems] = useState([])
  const [mobileNavItemsDisplay, setMobileNavItemsDisplay] = useState([])
  const [isInSubNav, setIsInSubNav] = useState(false)
  useEffect(() => {
    document.body.style.overflow = mobileMenuOpen ? 'hidden' : 'auto'
  }, [mobileMenuOpen])

  useEffect(() => {
    setMobileNavItems(siteInformation?.header_navigation?.tree || [])
    setMobileNavItemsDisplay(siteInformation?.header_navigation?.tree || [])
  }, [siteInformation])

  const changeMobileSubNav = parent => {
    if (parent?.children) {
      setMobileNavItemsDisplay([
        { ..._.omit(parent, ['children']), children: [] },
        ...parent.children,
      ])
      setIsInSubNav(true)
    }
  }

  const resetMobileSubNav = () => {
    setMobileNavItemsDisplay(mobileNavItems)
    setIsInSubNav(false)
  }

  const openMobileNav = () => {
    resetMobileSubNav()
    setMobileMenuOpen(true)
  }

  const closeMobileNav = () => {
    setMobileMenuOpen(false)
    resetMobileSubNav()
  }

  /* HIDE HEADER */
  const headerRef = useRef(null)
  const [showHeader, setShowHeader] = useState(true)
  const [lastScrollY, _setLastScrollY] = useState(0)

  // HACK
  const lastScrollYRef = useRef(lastScrollY)
  const setLastScrollY = data => {
    lastScrollYRef.current = data
    _setLastScrollY(data)
  }

  const checkHeaderVisibility = () => {
    if (hideHeader && typeof window !== 'undefined') {
      if (!mobileMenuOpen) {
        if (
          window.scrollY > lastScrollYRef.current &&
          window.scrollY > headerRef.current.offsetHeight
        ) {
          setShowHeader(false)
        } else {
          setShowHeader(true)
        }
        setLastScrollY(window.scrollY)
      }
    }
  }

  useEffect(() => {
    if (hideHeader && typeof window !== 'undefined') {
      window.addEventListener('scroll', checkHeaderVisibility, {
        passive: true,
      })

      // cleanup function
      return () => {
        window.removeEventListener('scroll', checkHeaderVisibility)
      }
    } else if (typeof window !== 'undefined') {
      window.removeEventListener('scroll', checkHeaderVisibility)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hideHeader])
  /* END HIDE HEADER */

  const { jobs: jobPublications } = useJobPublications(lang, {
    revalidateOnMount: true,
  })

  return (
    <header
      ref={headerRef}
      className={cx(styles.header, {
        invisible: !showHeader,
        'bg-white': color === 'white',
        'bg-yellow': color === 'yellow',
        'bg-purple': color === 'blue',
        'h-fit': mobileMenuOpen,
        'h-auto': !mobileMenuOpen,
      })}
    >
      <div className={styles.headerContainer}>
        <div className={styles.leftSection} ref={logoSection}>
          <Link href={isHomepage ? '#' : '/'} passHref>
            <a>
              <div className={'sr-only'}>Paymenttools</div>
              <Logo
                color={
                  color === 'yellow'
                    ? 'blue_white'
                    : color === 'white'
                    ? 'blue_yellow'
                    : color === 'blue'
                    ? 'yellow_white'
                    : null
                }
                className="w-28 xs:w-36 sm:w-48"
              />
            </a>
          </Link>
        </div>

        <div className={cx(styles.middleSection, 'hidden lg:flex')}>
          <nav className={styles.headerNavigation}>
            <ul className={styles.navItemList}>
              {siteInformation &&
                siteInformation.header_navigation?.tree?.map(navEntry => {
                  const hasChildren =
                    navEntry.children && navEntry.children.length > 0

                  return (
                    <li
                      key={navEntry.page.id}
                      className={cx(styles.navItem, {
                        [styles.inactiveNavItem]:
                          activeNavigationItem?.id &&
                          activeNavigationItem.id !== navEntry.page.id,
                        [styles.navItemBlue]: color === 'blue',
                      })}
                      onMouseEnter={() => openPopout(navEntry)}
                      onClick={() => hasChildren && togglePopout(navEntry)}
                      onMouseLeave={() =>
                        !hasChildren && setActiveNavigationItem(null)
                      }
                    >
                      {hasChildren ? (
                        <span>{navEntry.page.title}</span>
                      ) : (
                        <a href={navEntry.page.url}>{navEntry.page.title}</a>
                      )}
                      {navEntry.page.showopenjobs &&
                      jobPublications &&
                      jobPublications.length > 0 ? (
                        <span
                          className={cx('openJobsBadge', {
                            badgeBlue: color === 'blue',
                          })}
                        >
                          {
                            jobPublications.filter(
                              job =>
                                (lang === 'de' && job.lang === 'de') ||
                                !!job?.[lang]
                            ).length
                          }
                        </span>
                      ) : (
                        ''
                      )}
                      {hasChildren &&
                        (activeNavigationItem?.id &&
                        activeNavigationItem.id === navEntry.page.id ? (
                          <ChevronUp />
                        ) : (
                          <ChevronDown />
                        ))}
                    </li>
                  )
                })}
            </ul>
          </nav>
        </div>

        <div className={cx(styles.rightSection, 'hidden lg:flex')}>
          <Button
            href="https://account.paymenttools.com/"
            as="a"
            color={color === 'blue' ? 'transparent-white' : 'transparent'}
            small
            rel={'noreferrer nofollow'}
            target={'_blank'}
            className={'mr-4'}
          >
            {t('login')}
          </Button>
          <Button
            href={
              lang === 'de'
                ? '/kontakt'
                : lang === 'en'
                ? '/en/contact'
                : '/kontakt'
            }
            as="a"
            color={color === 'blue' ? 'yellow' : 'purple'}
            small
          >
            {t('contact')}
          </Button>
        </div>

        <div className={'block lg:hidden'}>
          <Menu
            className={cx(styles.mNavOpenButton, {
              'stroke-white': color === 'blue',
            })}
            onClick={openMobileNav}
          />
        </div>
      </div>

      {mobileMenuOpen && (
        <div className={cx(styles.mobileNavigation)}>
          <div className={styles.mNavContainer}>
            <div className={styles.mNavHeader}>
              <a href={isHomepage ? '#' : '/'}>
                <div className={'sr-only'}>Paymenttools</div>
                <Logo color={'blue_yellow'} className="w-28 xs:w-36 sm:w-48" />
              </a>

              <X className={styles.mNavCloseButton} onClick={closeMobileNav} />
            </div>

            <nav className={styles.mNavEntries}>
              <ul className={'p-0'}>
                {isInSubNav && (
                  <li
                    key={'mNavBack'}
                    className={cx(styles.mNavEntry, styles.small)}
                    onClick={resetMobileSubNav}
                  >
                    <div className="flex items-center">
                      <ChevronLeft className={'-ml-8'} /> zurück
                    </div>
                  </li>
                )}
                {mobileNavItemsDisplay &&
                  mobileNavItemsDisplay.map(navEntry => (
                    <li key={navEntry.page.id} className={cx(styles.mNavEntry)}>
                      {navEntry.children && navEntry.children.length > 0 && (
                        <div
                          className="flex items-center"
                          onClick={e =>
                            navEntry.children && navEntry.children.length > 0
                              ? changeMobileSubNav(navEntry)
                              : e.preventDefault()
                          }
                        >
                          {navEntry.page.title} <ChevronRight />
                        </div>
                      )}
                      {!navEntry.children ||
                        (navEntry.children.length === 0 && (
                          <a href={navEntry?.page?.url}>
                            {navEntry.page.title}
                          </a>
                        ))}
                    </li>
                  ))}
              </ul>
            </nav>

            <div className={styles.bottomSection}>
              <div className={styles.mNavButtonSection}>
                <Button
                  href="https://account.paymenttools.com/"
                  as="a"
                  color={'white'}
                  small
                  rel={'nofollow'}
                  target={'_blank'}
                >
                  {t('login')}
                </Button>
                <Button href="/kontakt" as="a" color={'purple'} small>
                  {t('contact')}
                </Button>
              </div>

              <div className={styles.mNavHelpSection}>
                <div className={styles.mNavHelpSectionContainer}>
                  <div className={styles.mNavHelpSectionTitle}>
                    {siteInformation?.header_navigation_extra?.right_title}
                  </div>
                  <div>
                    {siteInformation?.header_navigation_extra?.right_text}
                  </div>
                  <div className={'mt-8'}>
                    <Button
                      href={
                        siteInformation?.header_navigation_extra
                          ?.right_button_link || '#'
                      }
                      as="a"
                      color={'transparent'}
                      small
                    >
                      {
                        siteInformation?.header_navigation_extra
                          ?.right_button_title
                      }
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <div
        ref={navPopout}
        className={cx(styles.navPopout, {
          hidden: !activeNavigationItem?.id || !activeNavigationItem.childCount,
          'hidden lg:flex':
            !!activeNavigationItem?.id && !!activeNavigationItem.childCount,
        })}
        style={{ top: (headerRef?.current?.offsetHeight || 0) + 'px' }}
        onMouseLeave={() => setActiveNavigationItem(null)}
      >
        <div className={styles.popoutContainer}>
          <div
            className={styles.leftSection}
            style={{ maxWidth: leftSectionWidth ? leftSectionWidth : 'auto' }}
          >
            <div
              className={styles.popoutSection}
              style={{ maxWidth: Math.min(285, leftSectionWidth) || 'auto' }}
            >
              <div className={styles.popoutSectionTitle}>
                {siteInformation?.header_navigation_extra?.left_title}
              </div>
              <div>{siteInformation?.header_navigation_extra?.left_text}</div>
            </div>
          </div>
          <div className={styles.middleSection}>
            <div className={styles.popoutSection}>
              {siteInformation?.header_navigation?.tree?.map(navEntry => (
                <div
                  key={navEntry?.page.id}
                  className={cx({
                    hidden:
                      activeNavigationItem?.id &&
                      navEntry.page.id !== activeNavigationItem.id,
                  })}
                >
                  <div className={cx(styles.popoutSectionNavItemHeader)}>
                    <a href={navEntry?.page?.url || '#'}>
                      {navEntry?.page.title}
                    </a>
                  </div>
                  {navEntry.children?.map(child => (
                    <div
                      key={child.page.id}
                      className={styles.popoutSectionNavItem}
                    >
                      <a href={child?.page?.url || '#'}>{child.page.title}</a>
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </div>
          <div className={styles.rightSection}>
            <div className={styles.popoutSection}>
              <div className={styles.popoutSectionTitle}>
                {siteInformation?.header_navigation_extra?.right_title}
              </div>
              <div>{siteInformation?.header_navigation_extra?.right_text}</div>
              <div className={'mt-8'}>
                <Button
                  href={
                    siteInformation?.header_navigation_extra
                      ?.right_button_link || '#'
                  }
                  as="a"
                  color={'transparent'}
                  small
                >
                  {siteInformation?.header_navigation_extra?.right_button_title}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}

export default Header
