import cx from 'classnames'
import styles from './Section.module.scss'

const Section = ({
  children,
  classNames,
  color = 'white',
  gradient = false,
  gradientColor = 'white',
  gradientPosition = 'center',
}) => {
  return (
    <section
      className={cx(
        styles.section,
        {
          'bg-white': !gradient && color === 'white',
          'bg-yellow': !gradient && color === 'yellow',
          'bg-purple': !gradient && color === 'blue',
          'bg-purple-dark': !gradient && color === 'dark-blue',
          // WHITE
          'bgg-white-yellow-25':
            gradient &&
            color === 'white' &&
            gradientColor === 'yellow' &&
            gradientPosition === 'top',
          'bgg-white-yellow-50':
            gradient &&
            color === 'white' &&
            gradientColor === 'yellow' &&
            gradientPosition === 'center',
          'bgg-white-yellow-75':
            gradient &&
            color === 'white' &&
            gradientColor === 'yellow' &&
            gradientPosition === 'bottom',
          'bgg-white-blue-25':
            gradient &&
            color === 'white' &&
            gradientColor === 'blue' &&
            gradientPosition === 'top',
          'bgg-white-blue-50':
            gradient &&
            color === 'white' &&
            gradientColor === 'blue' &&
            gradientPosition === 'center',
          'bgg-white-blue-75':
            gradient &&
            color === 'white' &&
            gradientColor === 'blue' &&
            gradientPosition === 'bottom',
          'bgg-white-dark_blue-25':
            gradient &&
            color === 'white' &&
            gradientColor === 'dark-blue' &&
            gradientPosition === 'top',
          'bgg-white-dark_blue-50':
            gradient &&
            color === 'white' &&
            gradientColor === 'dark-blue' &&
            gradientPosition === 'center',
          'bgg-white-dark_blue-75':
            gradient &&
            color === 'white' &&
            gradientColor === 'dark-blue' &&
            gradientPosition === 'bottom',
          // YELLOW
          'bgg-yellow-white-25':
            gradient &&
            color === 'yellow' &&
            gradientColor === 'white' &&
            gradientPosition === 'top',
          'bgg-yellow-white-50':
            gradient &&
            color === 'yellow' &&
            gradientColor === 'white' &&
            gradientPosition === 'center',
          'bgg-yellow-white-75':
            gradient &&
            color === 'yellow' &&
            gradientColor === 'white' &&
            gradientPosition === 'bottom',
          'bgg-yellow-blue-25':
            gradient &&
            color === 'yellow' &&
            gradientColor === 'blue' &&
            gradientPosition === 'top',
          'bgg-yellow-blue-50':
            gradient &&
            color === 'yellow' &&
            gradientColor === 'blue' &&
            gradientPosition === 'center',
          'bgg-yellow-blue-75':
            gradient &&
            color === 'yellow' &&
            gradientColor === 'blue' &&
            gradientPosition === 'bottom',
          'bgg-yellow-dark_blue-25':
            gradient &&
            color === 'yellow' &&
            gradientColor === 'dark-blue' &&
            gradientPosition === 'top',
          'bgg-yellow-dark_blue-50':
            gradient &&
            color === 'yellow' &&
            gradientColor === 'dark-blue' &&
            gradientPosition === 'center',
          'bgg-yellow-dark_blue-75':
            gradient &&
            color === 'yellow' &&
            gradientColor === 'dark-blue' &&
            gradientPosition === 'bottom',
          // BLUE
          'bgg-blue-white-25':
            gradient &&
            color === 'blue' &&
            gradientColor === 'white' &&
            gradientPosition === 'top',
          'bgg-blue-white-50':
            gradient &&
            color === 'blue' &&
            gradientColor === 'white' &&
            gradientPosition === 'center',
          'bgg-blue-white-75':
            gradient &&
            color === 'blue' &&
            gradientColor === 'white' &&
            gradientPosition === 'bottom',
          'bgg-blue-yellow-25':
            gradient &&
            color === 'blue' &&
            gradientColor === 'yellow' &&
            gradientPosition === 'top',
          'bgg-blue-yellow-50':
            gradient &&
            color === 'blue' &&
            gradientColor === 'yellow' &&
            gradientPosition === 'center',
          'bgg-blue-yellow-75':
            gradient &&
            color === 'blue' &&
            gradientColor === 'yellow' &&
            gradientPosition === 'bottom',
          'bgg-blue-dark_blue-25':
            gradient &&
            color === 'blue' &&
            gradientColor === 'dark-blue' &&
            gradientPosition === 'top',
          'bgg-blue-dark_blue-50':
            gradient &&
            color === 'blue' &&
            gradientColor === 'dark-blue' &&
            gradientPosition === 'center',
          'bgg-blue-dark_blue-75':
            gradient &&
            color === 'blue' &&
            gradientColor === 'dark-blue' &&
            gradientPosition === 'bottom',
          // DARK BLUE
          'bgg-dark_blue-white-25':
            gradient &&
            color === 'blue-white' &&
            gradientColor === 'white' &&
            gradientPosition === 'top',
          'bgg-dark_blue-white-50':
            gradient &&
            color === 'blue-white' &&
            gradientColor === 'white' &&
            gradientPosition === 'center',
          'bgg-dark_blue-white-75':
            gradient &&
            color === 'blue-white' &&
            gradientColor === 'white' &&
            gradientPosition === 'bottom',
          'bgg-dark_blue-yellow-25':
            gradient &&
            color === 'dark-blue' &&
            gradientColor === 'yellow' &&
            gradientPosition === 'top',
          'bgg-dark_blue-yellow-50':
            gradient &&
            color === 'dark-blue' &&
            gradientColor === 'yellow' &&
            gradientPosition === 'center',
          'bgg-dark_blue-yellow-75':
            gradient &&
            color === 'dark-blue' &&
            gradientColor === 'yellow' &&
            gradientPosition === 'bottom',
          'bgg-dark_blue-blue-25':
            gradient &&
            color === 'dark-blue' &&
            gradientColor === 'blue' &&
            gradientPosition === 'top',
          'bgg-dark_blue-blue-50':
            gradient &&
            color === 'dark-blue' &&
            gradientColor === 'blue' &&
            gradientPosition === 'center',
          'bgg-dark_blue-blue-75':
            gradient &&
            color === 'dark-blue' &&
            gradientColor === 'blue' &&
            gradientPosition === 'bottom',
        },
        classNames
      )}
    >
      {children}
    </section>
  )
}

export default Section
