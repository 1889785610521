import { useEffect, useRef, useState } from 'react'
import ImageComponent from '../../Image'
import Video from '../../Video'
import styles from './Media.module.scss'
import cx from 'classnames'
import Section from '../../Section'

const Media = ({ content, isSection, className, playerProps, imageProps }) => {
  const playerRef = useRef(null)
  const [videoUrl, setVideoUrl] = useState('')

  const handlePlayerReady = player => {
    playerRef.current = player

    // You can handle player events here, for example:
    player.on('waiting', () => {
      player.log('player is waiting')
    })

    player.on('dispose', () => {
      player.log('player will dispose')
    })
  }

  const {
    mediatype: mediaType,
    linkedvideo: linkedVideo,
    uploadedasset: uploadedAsset,
    mediablock_color,
    mediablock_gradient: gradient,
    mediablock_gradient_color: gradientColor,
    mediablock_gradient_position: gradientPosition,
    zoom_enabled,
  } = content

  useEffect(() => {
    setVideoUrl(
      mediaType.value === 'linked_video'
        ? linkedVideo
        : mediaType.value === 'uploaded_asset'
        ? uploadedAsset?.permalink
        : null
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (
    !mediaType?.value ||
    (mediaType.value === 'uploaded_asset' && !uploadedAsset) ||
    (mediaType.value === 'linked_video' && !linkedVideo)
  ) {
    return null
  }

  return isSection ? (
    <Section
      classNames={styles.sectionWrapper}
      color={mediablock_color?.value}
      gradient={gradient}
      gradientColor={gradientColor?.value}
      gradientPosition={gradientPosition?.value}
    >
      <div>
        {mediaType.value === 'uploaded_asset' &&
        (uploadedAsset?.is_image ||
          uploadedAsset?.basename.endsWith('.svg')) ? (
          <ImageComponent
            src={uploadedAsset?.permalink}
            width={uploadedAsset?.width || 0}
            height={uploadedAsset?.height || 0}
            layout={'responsive'}
            {...imageProps}
            zoom={
              zoom_enabled && !uploadedAsset?.basename.endsWith('.svg') ? 1 : 0
            }
          />
        ) : mediaType.value === 'uploaded_asset' && uploadedAsset?.is_video ? (
          videoUrl && (
            <Video
              options={{
                muted: false,
                autoplay: false,
                controls: false,
                fluid: true,
                preload: true,
                sources: [
                  {
                    src: videoUrl + '?#t=0.001',
                  },
                ],
              }}
              {...playerProps}
              onReady={handlePlayerReady}
            />
          )
        ) : (
          ''
        )}
      </div>
    </Section>
  ) : (
    <div
      className={cx(className, {
        'bg-white': isSection && mediablock_color?.value === 'white',
        'bg-yellow': isSection && mediablock_color?.value === 'yellow',
        'bg-purple': isSection && mediablock_color?.value === 'blue',
      })}
    >
      {mediaType.value === 'uploaded_asset' &&
      (uploadedAsset?.is_image || uploadedAsset?.basename.endsWith('.svg')) ? (
        <ImageComponent
          src={uploadedAsset?.permalink}
          width={uploadedAsset?.width || 0}
          height={uploadedAsset?.height || 0}
          layout={'responsive'}
          {...imageProps}
          zoom={
            zoom_enabled && !uploadedAsset?.basename.endsWith('.svg') ? 1 : 0
          }
        />
      ) : mediaType.value === 'uploaded_asset' && uploadedAsset?.is_video ? (
        videoUrl && (
          <Video
            options={{
              muted: false,
              autoplay: false,
              controls: false,
              fluid: true,
              preload: true,
              sources: [
                {
                  src: videoUrl + '?#t=0.001',
                },
              ],
            }}
            {...playerProps}
            onReady={handlePlayerReady}
          />
        )
      ) : (
        ''
      )}
    </div>
  )
}

export default Media
