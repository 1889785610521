export default function cleanString(str = '') {
  /*.replace(/<br\s*\\?>/gs, '\n')*/
  return str?.replace(/^<p>(.*?)<\/p>$/gs, '$1') || str
}

export function stripHtml(str = '') {
  return (
    str?.replace(/<\/?[^>]+(>|$)/g, '') || str?.replace(/<[^>]*>?/gm, '') || str
  )
}
